import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '67.5%',
            marginBottom: '330px',
            marginLeft: '27.99%',
            marginTop: '237px',
            [theme.breakpoints.down(1000)]: {
                width: '70%',
                marginLeft: '22%',
            },
            [theme.breakpoints.down(701)]: {
                width: '71%',
                marginLeft: '21%',
            },
            [theme.breakpoints.down(650)]: {
                marginBottom: '200px',
                marginTop: '150px',
            },
            [theme.breakpoints.down(501)]: {
                marginBottom: '85px',
                marginTop: '125px',
            },
        },
        separator: {
            marginBottom: '140px',
            background: '#3D4274',
        },
        titleContainer: {
            marginBottom: '133px',
            marginTop: '15px',
            '& #bot': { marginLeft: '59.5px' },
            [theme.breakpoints.down(1000)]: {
                marginBottom: '70px',
                '& #bot': { marginLeft: 0 },
            },
            [theme.breakpoints.down(701)]: {
                marginBottom: '60px',
                marginTop: '15px',
            },
        },
        title: {
            fontSize: '59.5px',
            color: '#3D4975',
            letterSpacing: '7px',
            fontWeight: 900,
            marginBottom: 0,
            [theme.breakpoints.down(1000)]: {
                fontSize: '55.24px',
                letterSpacing: '6.34px',
            },
            [theme.breakpoints.down(701)]: {
                fontSize: '52.24px',
                letterSpacing: '6px',
            },
            [theme.breakpoints.down(650)]: {
                fontSize: '45px',
                letterSpacing: '5.5px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '29.85px',
                letterSpacing: '3.43px',
            },
        },
        section: {
            marginBottom: '140px',
            [theme.breakpoints.down(650)]: { marginBottom: '100px' },
        },
        lastSection: {
            marginBottom: '0px',
            '& p': { fontWeight: 700 },
            [theme.breakpoints.down(650)]: { marginBottom: '100px' },
        },
        subtitle: {
            fontSize: '32.07px',
            color: '#3D4274',
            letterSpacing: '2px',
            lineHeight: '50px',
            fontWeight: 500,
            width: '100%',
            marginBottom: '63px',
            [theme.breakpoints.down(1000)]: {
                fontSize: '34px',
                letterSpacing: '1.2px',
                lineHeight: '52px',
            },
            [theme.breakpoints.down(701)]: {
                fontSize: '28.13px',
                letterSpacing: '2px',
                lineHeight: '42px',
            },
            [theme.breakpoints.down(650)]: {
                fontSize: '25px',
                letterSpacing: '1.8px',
                lineHeight: '40px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '16.07px',
                letterSpacing: '1.14px',
                lineHeight: '25px',
                marginBottom: '40px',
            },
        },
        text: {
            fontSize: '18.3px',
            color: '#3D4274',
            letterSpacing: '0.78px',
            lineHeight: '26.35px',
            fontWeight: 500,
            [theme.breakpoints.down(769)]: {
                fontSize: '21px',
                letterSpacing: '0.75px',
                lineHeight: '30px',
            },
            [theme.breakpoints.down(701)]: {
                fontSize: '19px',
                letterSpacing: '0.34px',
                lineHeight: '26.35px',
            },
            [theme.breakpoints.down(650)]: {
                fontSize: '16px',
                letterSpacing: '0.5px',
                lineHeight: '22px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '10.86px',
                letterSpacing: '0.25px',
                lineHeight: '20px',
            },
        },
        textWrapper: {
            columnCount: 2,
            columnGap: '35px',
            columnWidth: '48%',
            '& hr': {
                marginBottom: '18.3px',
                marginTop: '18.3px',
                background: '#3D4274',
            },
            [theme.breakpoints.down(900)]: { columnCount: 1 },
        },
    });
