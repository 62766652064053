// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

// local imports
import { styles } from './NoticePrivacy.style';

function NoticePrivacy(props: WithStyles<typeof styles>) {
    const { classes } = props;
    const column = useMediaQuery('(min-width:900px)');

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Box className={classes.titleContainer}>
                    <p className={classes.title}>AVISO DE</p>
                    <p id="bot" className={classes.title}>
                        PRIVACIDAD
                    </p>
                </Box>

                <Box className={classes.section}>
                    <p className={classes.subtitle}>Simplificado</p>
                    <p className={classes.text}>
                        Cuenca Tecnología Financiera, S.A. de C.V., Institución de Fondos de Pago Electrónico (“Cuenca”)
                        es la responsable del tratamiento de los datos personales que se recolectan a través de su
                        plataforma, página de internet y medios electrónicos de contacto y/o soporte al cliente.
                    </p>
                    <p className={classes.text}>
                        Los datos personales que se recaban por Cuenca serán utilizados con la finalidad de
                        proporcionar, mantener y mejorar nuestros servicios financieros; y podrá realizar transferencias
                        de datos únicamente con las empresas integrantes de su mismo grupo empresarial, con los terceros
                        y autoridades referidos en el Aviso de Privacidad únicamente a fin de dar cumplimiento a la
                        regulación aplicable a las Instituciones de Fondos de Pago Electrónico.
                    </p>
                    <p className={classes.text}>
                        Cuenca no venderá, cederá o transferirá los datos personales del cliente o usuario a terceros
                        ajenos a la empresa, sus filiales, subsidiarias y partes relacionadas, o terceros distintos a
                        los anteriormente mencionados sin su consentimiento previo.
                    </p>
                    <p className={classes.text}>
                        Si desea conocer nuestro Aviso de Privacidad integral, lo podrá consultar en la siguiente
                        dirección electrónica o directamente en nuestra aplicación móvil:{' '}
                        <em>
                            <strong>https://cuenca.com/aviso-privacidad</strong>
                        </em>
                        .
                    </p>
                </Box>

                <hr className={classes.separator} />

                <Box className={classes.section}>
                    <p className={classes.subtitle}>Integral</p>
                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Cuenca Tecnología Financiera, S.A. de C.V., Institución de Fondos de Pago Electrónico,
                            conocida comercialmente y en lo sucesivo como “Cuenca”, con domicilio para oír y recibir
                            notificaciones en el ubicado en Varsovia 36, Colonia Juárez, Alcaldía Cuauhtémoc, Código
                            Postal 06600, Ciudad de México, es el responsable del uso y protección de sus datos
                            personales, y al respecto le informamos lo siguiente:
                        </p>
                        <p className={classes.text}>
                            Este Aviso de Privacidad está elaborado en cumplimiento con las disposiciones legales en
                            materia de protección de datos personales de la Ley Federal de Protección de Datos
                            Personales en Posesión de Particulares (LFPDPPP), su Reglamento y los Lineamientos del Aviso
                            de Privacidad emitidos por el Instituto Nacional de Transparencia, Acceso a la Información y
                            Protección de Datos Personales (INAI).
                        </p>
                        <p className={classes.text}>
                            Para Cuenca es muy importante la seguridad de sus datos personales, por lo que contamos con
                            mecanismos tecnológicos, físicos, administrativos y legales para proteger y salvaguardar sus
                            datos personales, así como para evitar el daño, pérdida, destrucción, robo, extravío,
                            alteración y cualquier tratamiento no autorizado de los mismos.
                        </p>
                        <p className={classes.text}>
                            Cuenca pone a su disposición este Aviso de Privacidad, para informarle la forma en la que
                            recabamos, transferimos y utilizamos su información confidencial, y el mismo, se encuentra
                            disponible en nuestro sitio web y aplicación.
                        </p>
                    </Box>
                </Box>

                <Box className={classes.section}>
                    <p className={classes.subtitle}>
                        Las finalidades del tratamiento de los datos personales son las siguientes:
                    </p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>Proporcionar, mantener y mejorar nuestros servicios.</p>
                        <hr />
                        <p className={classes.text}>
                            Cumplir con disposiciones de identificación y conocimiento del cliente en materia de
                            prevención de lavado de dinero y financiamiento al terrorismo de conformidad con lo
                            establecido por el artículo 58 de la vigente Ley para Regular las Instituciones de
                            Tecnología Financiera (LRITF) y sus disposiciones secundarias.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            Corroborar la identidad y veracidad de la información proporcionada por nuestros clientes y
                            usuarios.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            Compartir con el cliente información relacionada, respecto de recordatorios, notificaciones,
                            confirmaciones, cancelaciones, resúmenes de transacciones, avisos, actualizaciones,
                            mensajes.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            Atender a las solicitudes, aclaraciones, consultas, reclamaciones, comentarios, peticiones y
                            cualquier tema relacionado con proporcionar atención y servicio al cliente.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            Analizar las tendencias, usos y actividades en relación con nuestros servicios, así como
                            supervisarlas.
                        </p>
                        {column ? null : <hr />}
                        <p className={classes.text}>
                            Prevenir actividades ilegales, transacciones fraudulentas, en aras de garantizar la
                            continuidad y seguridad en el sistema de pagos.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            Proporcionar información al cliente acerca de nuestros servicios, productos, alianzas,
                            promociones, noticias, eventos e información que consideramos pueda ser de interés para el
                            cliente.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            Proporcionar contenido personalizado sobre nuestros Servicios de acuerdo a las preferencias
                            e intereses de los clientes.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            Ofrecer un mejor servicio a través de la vinculación con información que obtenemos de
                            terceros para ayudar a comprender mejor las necesidades de nuestros clientes.
                        </p>
                    </Box>
                </Box>

                <Box className={classes.section}>
                    <p className={classes.subtitle}>
                        De las finalidades antes previstas, las siguientes no son necesarias para proporcionar el
                        servicio ofrecido por Cuenca, pero nos permiten y facilitan brindar al cliente una mejor
                        atención:
                    </p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>Crear bases de datos para lograr estudios de mercado.</p>
                        <hr />
                        <p className={classes.text}>
                            Enviar notificaciones, avisos, mensajes promocionales o comunicaciones con fines de
                            mercadotecnia, publicidad o telemarketing sobre productos y servicios nuevos o existentes
                            ofrecidos por Cuenca o mediante alianzas comerciales;
                            <br />
                            Realizar encuestas, estadísticas, estudios de mercado, registros sobre hábitos de consumo a
                            través de herramientas de captura automática de datos, intereses y comportamiento.
                            <br />
                            En caso de que no desee que sus datos personales sean tratados para los fines previstos en
                            los dos párrafos que anteceden, desde este momento el cliente nos puede comunicar lo
                            anterior, mediante el envío de un correo eletrónico a la siguiente dirección{' '}
                            <em>
                                <strong> soporte@cuenca.com</strong>
                            </em>
                            señalando la intención de excluir el tratamiento de sus datos personales para los fines
                            adicionales. Desde la recepción del correo, Cuenca contará con un plazo máximo de veinte
                            días hábiles para comunicar al cliente la determinación adoptada y, en su caso, ejecutarla
                            dentro de un plazo máximo de quince días hábiles adicionales, por lo que acontecidos estos
                            plazos, Cuenca se obliga a no tratar los datos personales para las finalidades antes
                            mencionadas sin que esto se entienda que el Aviso de Privacidad deje de estar en vigor. La
                            oposición prevista en el presente párrafo, solo constituye una reserva en el tratamiento de
                            los datos personales del cliente para finalidades no necesarias en la prestación del
                            servicio ofrecido por Cuenca. Por lo que esta oposición no podrá ser un motivo determinante
                            para que le neguemos los servicios que solicita o contrata con Cuenca.
                        </p>
                    </Box>
                </Box>

                <Box className={classes.section}>
                    <p className={classes.subtitle}>
                        Los datos personales que Cuenca recauda del cliente y usuario necesarios para la prestación del
                        servicio, son los siguientes:
                    </p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Apellido paterno, apellido materno y nombre o nombres sin abreviaturas; género; fecha de
                            nacimiento; entidad federativa de nacimiento; país de nacimiento; nacionalidad; clave de
                            elector, en su caso; domicilio particular del país de residencia y en caso de tener
                            domicilio en territorio nacional, también se recabará éste, compuesto por nombre de la
                            calle, avenida o vía de que se trate, debidamente especificada; número exterior y, en su
                            caso, interior; colonia o urbanización; alcaldía, delegación, municipio o demarcación
                            política similar que corresponda, en su caso; ciudad o población, entidad federativa,
                            estado, provincia, departamento o demarcación política similar que corresponda, en su caso;
                            código postal y país; ocupación, profesión, actividad o giro del negocio al que se dedica el
                            cliente; Clave Única de Registro de Poblicación (CURP); número de Registro Federal del
                            Contribuyente (RFC), firma autógrafa digitalizada; número telefónico; dirección de correo
                            electrónico; en su caso, número de cuenta y Clave Bancaria Estandarizada (CLABE) en la
                            Entidad Financiera o Entidad Financiera Extranjera autorizadas para recibir depósitos; de
                            conversión a fondos de pago electrónico o bien, de inversión en o de recepción de recursos
                            de operaciones de financiamiento colectivo, según trate, y que corresponda con el nombre del
                            cliente; la geolocalización del dispositivo móvil desde el cual el Cliente abra su cuenta o
                            celebre el contrato respectivo y, en algunos casos, que se considere pertinente a criterio
                            de Cuenca se solicitarán los documentos digitalizados que comprueben la veracidad de los
                            datos proporcionados a Cuenca así como datos biométricos consistentes en reconocimiento
                            facial. Lo anterior sin perjuicio de la facultad de Cuenca de requerir mayor información y/o
                            documentación en cumplimiento a la normatividad, políticas, procedimientos y demás
                            lineamientos establecidos en materia de prevención de lavado de dinero y financiamiento al
                            terrorismo y de conformidad con lo establecido en la Cláusula Vigésima Séptima del Contrato
                            de Adhesión de Emisión y Depósito Mercantil de Fondos de Pago Electrónico que rija la
                            relación jurídica.
                        </p>
                        <p className={classes.text}>
                            Asimismo, los datos personales que se consideren dentro de las siguientes categorías, sin
                            incluir datos personales sensibles:
                        </p>
                        <p className={classes.text}>Datos de identificación</p>
                        <hr />
                        <p className={classes.text}>Datos de contacto</p>
                        <hr />
                        <p className={classes.text}>Datos laborales</p>
                        <hr />
                        <p className={classes.text}>Datos académicos</p>
                        <hr />
                        <p className={classes.text}>Datos financieros</p>
                        <hr />
                        <p className={classes.text}>Datos patrimoniales</p>
                    </Box>
                </Box>

                <Box className={classes.section}>
                    <p className={classes.subtitle}>Datos personales sensibles</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Cuenca no solicitará datos personales sensibles, los cuales de conformidad con el artículo 3
                            fracción VI de la LFPDPPP son: aquellos datos personales que afecten a la esfera más íntima
                            de su titular, o cuya utilización indebida pueda dar origen a discriminación o conlleve un
                            riesgo grave para éste. En particular, se consideran sensibles aquellos que puedan revelar
                            aspectos como origen racial o étnico, estado de salud presente y futuro, información
                            genética, creencias religiosas filosóficas y morales, afiliación sindical, opiniones
                            políticas, preferencia sexual.
                        </p>
                    </Box>
                </Box>

                <Box className={classes.section}>
                    <p className={classes.subtitle}>Transferencia de datos personales</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Le informamos que sus datos personales son compartidos dentro y fuera del país con las
                            siguientes empresas:
                        </p>
                        <p className={classes.text}>
                            Cuenca Incorporated, con domicilio en los Estados Unidos de América.
                        </p>
                        <p className={classes.text}>Cuenca Labs, S.A. de C.V.</p>
                        <p className={classes.text}>Tarjetas Cuenca, S.A. de C.V.</p>
                        <hr />
                        <p className={classes.text}>
                            La transferencia de datos con las empresas mencionadas se realizará bajo el entendido del
                            tratamiento de los mismos es realizado por el mismo responsable debido a que las citadas
                            empresas forman parte del mismo grupo empresarial que Cuenca y mantienen los mismos procesos
                            y políticas internas. Por lo anterior y de conformidad con lo establecido en el artículo 37
                            fracción III de la vigente LFPDPPP es que dicha transferencia no está sujeta al
                            consentimiento del cliente y su negativa para la realización de esta transferencia de datos
                            personales constituye un motivo para que Cuenca le niegue la prestación de servicios
                            solicitados o contratados.
                        </p>
                        <hr />
                        <p className={classes.text}>Así también son compartidos con:</p>
                        <p className={classes.text}>
                            Empresas que proveen a Cuenca el servicio de conexión al Sistema de Pagos Electrónicos
                            Interbancarios (SPEI) desarrollado y operado por el Banco de México para la realización de
                            transferencias que al cliente permite la emisión y redención de Fondos de Pago de Pago
                            Electrónico.
                        </p>
                        <p className={classes.text}>
                            Empresas que proveen a Cuenca el servicio de validación de datos biométricos para verificar
                            la coincidencia de los datos y documentos de identificación proporcionados por el usuario
                            con su prueba de vida (reconocimiento facial).
                        </p>
                        <p className={classes.text}>
                            Instituto Nacional Electoral (“INE”), para dar cumplimiento de a obligaciones y a fin de
                            evitar y prevenir el delito de usurpación de identidad, se comparten los datos de las
                            credenciales para votar de los usuarios que se identifiquen por este medio, a fin de
                            verificar la autenticidad y validez de la información proporcionada a Cuenca.
                        </p>
                        <p className={classes.text}>
                            Registro Nacional de Población (“RENAPO”), a fin de validar que los datos personales del
                            usuario coincidan con su Clave Única del Registro de Población (“CURP”) o, que la CURP
                            proporcionada con el usuario sea válida.
                        </p>
                        <p className={classes.text}>
                            Lo anterior para el debido y cabal cumplimiento de las obligaciones previstas en la vigente
                            LRITF y sus disposiciones secundarias, así como estar en posibilidades de cumplir con los
                            servicios ofrecidos y contenidos a través del Contrato de Adhesión y Emisión de Depósito
                            Mercantil de Fondos de Pago Electrónico que rija la correspondiente relación jurídica entre
                            el Cliente y Cuenca. Por lo anterior y de conformidad con lo establecido en los artículos 37
                            fracciones IV y VII y 10 fracción I y IV de la vigente LFPDPPP es que dicha transferencia no
                            está sujeta al consentimiento del cliente y su negativa para la realización de esta
                            transferencia de datos personales constituye un motivo para que Cuenca le niegue la
                            prestación de servicios solicitados o contratados.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            Cuenca no venderá, cederá o transferirá los datos personales del cliente o usuario a
                            terceros ajenos a la empresa, sus filiales, subsidiarias y partes relacionadas, o terceros
                            distintos a los anteriormente mencionados sin su consentimiento previo. Cuenca podrá
                            transferir, sin necesidad de consentimiento previo por parte del cliente, aquellos datos
                            personales que sean necesarios para el cumplimiento de sus obligaciones con motivo de la
                            prestación de los servicios, los sometidos a un procedimiento previo de disociación, los
                            necesarios en el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial o
                            seguido en forma de juicio, así como aquellos solicitados por las autoridades que estén
                            debidamente facultadas a requerir dicha información.
                        </p>
                    </Box>
                </Box>

                <Box className={classes.section}>
                    <p className={classes.subtitle}>Derechos ARCO</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            El cliente tiene derecho a conocer los datos personales que almacenamos de él así como el
                            tratamiento que se le da a los mismos (Acceso). Asimismo, es su derecho solicitar la
                            corrección de su información personal en caso de que esté desactualizada, sea inexacta o
                            incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando
                            así lo solicite y/o considere que la misma no está siendo utilizada conforme a los
                            principios, deberes y obligaciones previstas en la normativa (Cancelación); así como
                            oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos
                            se conocen como derechos ARCO, mismos que pueden ser libremente ejercidos siempre y cuando
                            las solicitudes correspondientes no contravengan las disposiciones jurídicas aplicables al
                            caso concreto y cumplan con las formalidades previstas en este Aviso de Privacidad.
                        </p>
                        <p className={classes.text}>
                            Para el ejercicio de cualquiera de los derechos ARCO, el cliente deberá presentar la
                            solicitud respectiva mediante envío de correo electrónico a la dirección{' '}
                            <em>
                                <strong>soporte@cuenca.com</strong>
                            </em>
                            . Dicha solicitud deberá contener los requisitos siguientes: (i) nombre del titular de la
                            información, su domicilio y correo electrónico u otro medio para comunicar la respuesta;
                            (ii) documentos que acrediten su identidad o bien la representación legal correspondiente;
                            (iii) descripción clara y precisa de los datos personales respecto de los cuales se ejerza
                            el derecho correspondiente; y (iv) cualquier otro elemento que facilite la localización de
                            los datos personales. El cliente tiene la obligación de observar los requisitos pertinentes
                            para el ejercicio de sus Derechos ARCO. Asimismo, el derecho de Rectificación lo podrá
                            ejercer mediante mensaje a través de una aplicación ampliamente conocida y utilizada en todo
                            el mundo de mensajería para teléfonos inteligentes que envíe y recibe mensajes mediante
                            internet o desde nuestro sitio web
                            <em>
                                <strong>www.cuenca.com</strong>
                            </em>
                            . Recibida cualquier solicitud, Cuenca contará con el plazo de 20 días hábiles para
                            responder por el medio señalado por el cliente.
                        </p>
                    </Box>
                </Box>

                <Box className={classes.section}>
                    <p className={classes.subtitle}>
                        Revocación del consentimiento del cliente para el uso de sus datos personales
                    </p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            El cliente puede revocar el consentimiento que, en su caso, nos haya otorgado para el
                            tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no
                            en todos los casos Cuenca podrá atender su solicitud o concluir el uso de forma inmediata,
                            ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos
                            personales. Asimismo, el cliente deberá considerar que para ciertos fines, la revocación de
                            su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó,
                            o la conclusión de su relación con nosotros.
                        </p>
                        <p className={classes.text}>
                            Para revocar su consentimiento deberá presentar su solicitud en el siguiente correo
                            electrónico
                            <em>
                                <strong> soporte@cuenca.com</strong>
                            </em>
                            . La cual deberá contener los requisitos siguientes (i) nombre del titular de la
                            inforamación, su domicilio y correo electrónico u otro medio para comunicar la respuesta;
                            (ii) documentos que acrediten su identidad o bien la representación legal correspondiente;
                            (iii) descripción clara y precisa de los datos personales respecto de los cuales se ejerza
                            el derecho de revocación correspondiente; y (iv) cualquier otro elemento que facilite la
                            localización de los datos personales.
                        </p>
                    </Box>
                </Box>

                <Box className={classes.section}>
                    <p className={classes.subtitle}>Contacto</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Para mayor información sobre el ejercicio de cualquier derecho establecido en el presente
                            Aviso de Privacidad, el cliente podrá contactar al encargado del Departamento de Datos
                            Personales de Cuenca por correo electrónico según se describe a continuación:
                        </p>
                        <p className={classes.text}>
                            Correo electrónico:{' '}
                            <em>
                                <strong>soporte@cuenca.com</strong>
                            </em>
                            <br />
                            Teléfono de contacto (vía WhatsApp o cualquiera de las plataformas de comunicación que
                            forman parte de su mismo grupo): +52 55 1328 5364
                        </p>
                    </Box>
                </Box>

                <Box className={classes.section}>
                    <p className={classes.subtitle}>Limitación del uso o divulgación de su información personal</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Con el objeto de que usted pueda limitar el tratamiento de su información personal, le
                            recordamos los siguientes medios adicionales:
                        </p>
                        <p className={classes.text}>
                            Su inscripción en el Registro Público de Usuarios (REUS), un padrón brindado por la Comisión
                            Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros (CONDUSEF),
                            que contiene información personal de los usuarios del sistema financiero mexicano que no
                            desean recibir publicidad y/o promociones por parte de las instituciones financieras en sus
                            prácticas de mercadotecnia.
                        </p>
                        <p className={classes.text}>
                            Su inscripción en el Registro Público para Evitar Publicidad (REPEP), un padrón brindado por
                            la Procuraduría Federal del Consumidor, que contiene un listado de consumidores que no
                            desean recibir llamadas o mensajes de publicidad y/o promociones por parte de empresas
                            distintas a las instituciones financieras en sus prácticas de mercadotecnia.
                        </p>
                        <p className={classes.text}>
                            Lo señalado en los dos párrafos precedentes, no es aplicable en tratándose de llamadas que
                            pueda recibir con motivo de servicios de cobranza, fines políticos, caridad, beneficencia o
                            encuestas telefónicas.
                        </p>
                        <p className={classes.text}>
                            Para mayor información sobre estos registros, usted puede consultar el portal de Internet de
                            la CONDUSEF o de la PROFECO, o bien ponerse en contacto directo con estas, según
                            corresponda.
                        </p>
                    </Box>
                </Box>

                <Box className={classes.section}>
                    <p className={classes.subtitle}>El uso de tecnologías de rastreo en nuestro portal de Internet</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Nuestro sitio web{' '}
                            <em>
                                <strong>www.cuenca.com </strong>
                            </em>
                            automáticamente recopila información utilizando cookies. Cookie es un pequeño archivo con
                            información enviada por un sitio web y almacenada en el navegador del cliente en su disco
                            duro y nos ayuda a mejorar nuestros servicios y su experiencia sobre sus preferencias.
                        </p>
                        <p className={classes.text}>
                            Si el cliente así lo decide, puede configurar su navegador web para que borre o no acepte
                            cookies. Por favor tome en cuenta que, si usted decide borrar o no aceptar cookies, esto
                            podría afectar la dispobilidad y funcionalidad de nuestros servicios.
                        </p>
                        <p className={classes.text}>
                            Cuenca podría también recolectar información usando web beacons (también conocidos como
                            “gofs”, “pixel tags” y “píxeles de seguimiento”). Web beacons son imágenes electrónicas que
                            pueden ser usadas por nuestros servicios o correos electrónicos y ayudarnos a generar
                            cookies, contar las visitas, comprender el uso y la efectividad de una campaña y determinar
                            si un mensaje ha sido abierto y actuar en consecuencia.
                        </p>
                    </Box>
                </Box>

                <Box className={classes.section}>
                    <p className={classes.subtitle}>Modificaciones al Aviso de Privacidad</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            El presente Aviso de Privacidad puede sufrir modificaciones, cambios o actualizaciones
                            derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los
                            productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en
                            nuestro modelo de negocio, o por otras causas.
                        </p>
                        <p className={classes.text}>
                            Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente
                            aviso de privacidad, a través de nuestro sitio web
                            <em>
                                <strong> www.cuenca.com</strong>
                            </em>{' '}
                            y por medio de nuestra aplicación.
                        </p>
                        <p className={classes.text}>
                            Todas las modificaciones al presente Aviso de Privacidad serán publicadas en nuestro sitio
                            web
                            <em>
                                <strong> www.cuenca.com</strong>
                            </em>{' '}
                            y por medio de nuestra aplicación, por lo que el cliente tiene la obligación de revisar las
                            políticas con frecuencia a fin de enterarse de las posibles modificaciones. La entrada en
                            vigor de los cambios que sufra el presente Aviso de Privacidad será de 10 días de su
                            publicación, dentro de los 5 días de su publicación el cliente deberá manifestar si no está
                            conforme con las mismas, de ser así se suspenderá la prestación de Servicios. Vencido este
                            plazo, se considerará que el cliente acepta las modificaciones al Aviso de Privacidad.
                        </p>
                    </Box>
                </Box>

                <Box className={classes.section}>
                    <p className={classes.subtitle}>Aceptación</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Este Aviso de Privacidad está vinculado a los términos y condiciones y Contrato de Adhesión
                            puestos a su disposición dentro del sitio web
                            <em>
                                <strong>www.cuenca.com</strong>
                            </em>{' '}
                            y por medio de nuestra aplicación, los cuales constituyen un acuerdo legal entre el cliente
                            y Cuenca. El uso de los servicios proporcionados por Cuenca constituye la manifestación
                            tácita de la voluntad de estar de acuerdo con el presente Aviso de Privacidad, sin excluir
                            que exista una forma expresa adicional de manifestar la voluntad.
                        </p>
                    </Box>
                </Box>

                <Box className={classes.section}>
                    <p className={classes.subtitle}>Autoridad</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            La autoridad competente en materia de protección de datos personales en México es el
                            Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos
                            Personales (INAI). En caso de que el cliente considere que se están vulnerando sus derechos
                            puede acudir con el INAI para obtener mayor información y ayuda al respecto.
                        </p>
                    </Box>
                </Box>

                <Box className={classes.lastSection}>
                    <p className={classes.text}>
                        El 28 de septiembre de 2021 a las 21:30 hrs, presentamos una restricción de transacciones con
                        tarjeta VISA, lo cual genera como principal afectación la imposibilidad de realizar pagos y
                        compras con las tarjetas Visa emitidas por Accendo Banco, S.A., Institución de Banca Múltiple.
                        Si presentas esta afectación por favor contacta a nuestro equipo de soporte.
                    </p>
                </Box>
            </Grid>
        </Grid>
    );
}

export default withStyles(styles)(NoticePrivacy);
